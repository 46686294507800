import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  IconButton,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  OutlindedFieldContainer,
  StyledButton,
  FormText,
  FileContainer,
  DeleteIcon,
  StyledFormGroup,
  StyledTextField,
  StyledAlert,
} from "./styles";
import { FormTitle } from "../FormTitle";
import { useForm } from "react-hook-form";
import { colors } from "../../styles/variables";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: `${theme.spacing(1)} 0`,
        width: "100%",
      },
    },
    top: {
      color: `${colors.light}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);
const MailChimpForm = ({ status, message, onValidated, close }) => {
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const nameProps = {
    maxLength: 40,
  };
  const mobileProps = {
    maxLength: 10,
  };
  const messageProps = {
    maxLength: 1000,
  };

  const onSubmit = async ({ email, firstName, lastName, mobile, message }) => {
    setSubmitting(true);
    onValidated({
      EMAIL: email,
      MERGE1: firstName,
      MERGE2: lastName,
      MERGE3: mobile,
      MERGE4: message,
    });
    setSubmitting(false);
  };

  useEffect(() => {
    if (status === "sending") {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }

    if (status === "error") {
      setOpenError(true);
      setErrorMessage(message);
    } else {
      setOpenError(false);
      setErrorMessage(undefined);
    }

    if (status === "success") {
      setOpenSucceed(true);
      setTimeout(() => {
        close();
      }, 1200);
    } else {
      setOpenSucceed(false);
    }
  }, [status, message]);
  return (
    <>
      <FormTitle>Contact Us Today!</FormTitle>
      <OutlindedFieldContainer>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid container item spacing={3}>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="First name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="firstName"
                  error={errors.firstName ? true : false}
                />
                <span className="error-message">
                  {errors.firstName && "First name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Last name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="lastName"
                  error={errors.lastName ? true : false}
                />
                <span className="error-message">
                  {errors.lastName && "Last name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  name="email"
                  error={errors.email ? true : false}
                />
                <span className="error-message">
                  {errors.email && "Email address is required"}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField
                label="Mobile"
                variant="outlined"
                type="text"
                inputRef={register({
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: /[0][0-9]{9}/,
                })}
                inputProps={mobileProps}
                name="mobile"
                error={errors.mobile ? true : false}
              />
              <span className="error-message">
                {errors.mobile && "10 digits mobile number is required"}
              </span>
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField
                label="Message"
                variant="outlined"
                multiline
                minRows={6}
                type="text"
                inputRef={register({ required: true, maxLength: 1000 })}
                inputProps={messageProps}
                name="message"
                error={errors.message ? true : false}
              />
              <span className="error-message">
                {errors.message && "Message is required"}
              </span>
            </Grid>
            <Collapse in={openSucceed}>
              <StyledAlert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSucceed(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                We will get back to you soon!
              </StyledAlert>
            </Collapse>
            <Collapse in={openError}>
              <StyledAlert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </StyledAlert>
            </Collapse>

            <Grid container item spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </OutlindedFieldContainer>
    </>
  );
};

export default MailChimpForm;
