import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailChimpForm from "./mailchimp-form";

const MailChimpContainer = ({ close }) => {
  const mailchimpUrl =
    "https://gippslandorthodontic.us18.list-manage.com/subscribe/post?u=805664a1a8020628a29b8c2b2&amp;id=5a0c956b85";

  return (
    <MailchimpSubscribe
      url={mailchimpUrl}
      render={({ subscribe, status, message }) => (
        <MailChimpForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
          close={close}
        />
      )}
    />
  );
};

export default MailChimpContainer;
